import React, { useEffect, useRef, useState } from 'react'
import { Form, FormControl } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import ConjugationModel from '../../models/ConjugationModel';

export const AdminConjugationEdit = () => {
    
    const [verb, setVerb] = useState("");
    const [tense, setTense] = useState("");
  
    const [conjugations, setConjugations] = useState<ConjugationModel[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [httpError, setHttpError] = useState(null);
    const subjectConjugationMap= useRef  (new Map<number, string>());
 
  
    const params = useParams();
    const baseURL=process.env.REACT_APP_SPRING_API_URL;
    const navigate = useNavigate();
  
    const handleChange = (event: any) => {
      let currentConjugation = conjugations.find(
        (conjugation) => conjugation.id === event.target.id
      );
        
      if(currentConjugation!==undefined){
        currentConjugation.conjugation=event.target.value;
      }
      
      setConjugations([...conjugations]);
      subjectConjugationMap.current.set(Number(currentConjugation?.subjectModel.id), event.target.value);
    
    };

    function validateAPIResponse(response: Response) {
        if (response != null && !response.ok) {
            throw new Error("Something went wrong!");
        }
    }
    const convertMapToObject = <K, V>(map: Map<K, V>): Record<string, V> => {
        const obj: Record<string, V> = {};
        for (const [key, value] of map.entries()) {
          obj[String(key)] = value;
        }
        return obj;
      };

  
    const handleSubmit = async (e: any) => {
      
        const url = `${baseURL}/api/conjugations?verbID=${params.verbid}&tenseID=${params.tenseid}`;
        
        const authJson = localStorage.getItem("okta-token-storage");
        const authStateToken = JSON.parse(authJson !== null ? authJson : "");

        const requestOptions = {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${authStateToken?.accessToken?.accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(convertMapToObject(subjectConjugationMap.current)),
        };

        const createConjugationResponse = await fetch(url, requestOptions);
        validateAPIResponse(createConjugationResponse);

        alert("Conjugation created successfully!");
       navigate("/adminconjugationshome");
  

    };
  
    useEffect(() => {
      const fetchTodos = async () => {
        const url: string = `${baseURL}/api/conjugations?verbid=${params.verbid}&tenseid=${params.tenseid}`;
  
        const response = await fetch(url);
  
        if (response != null && !response.ok) {
          throw new Error("Something went wrong!");
        }
  
        const responseJson = await response.json();
  
        const responseData = responseJson;
        const loadedConjugations: ConjugationModel[] = [];
  
        
  
        for (const key in responseData) {
  
          loadedConjugations.push({
            id: responseJson[key].id,
            conjugation: responseJson[key].conjugation,
            verb: responseJson[key].verb.verb,
            subjectModel: responseData[key].subject,
            quiz: responseJson[key].quiz.quiz,
            tense: responseJson[key].tense.tense,
          });
          subjectConjugationMap.current.set(Number(responseData[key].subject.id), responseData[key].conjugation);
        }
  
        setVerb(loadedConjugations[0].verb);
        setTense(loadedConjugations[0].tense);
  
        setConjugations(loadedConjugations);
        setIsLoading(false);
      };
      fetchTodos().catch((error: any) => {
        setIsLoading(false);
        setHttpError(error.message);
      });
    }, [baseURL,params.verbid,params.tenseid]);
  
    if (isLoading) {
      return <h1>Loading</h1>;
    }
  
    if (httpError) {
      return (
        <div className="container m-5">
          <p>{httpError}</p>
        </div>
      );
    }
  

    return (
      <Container>
        <Form id="conjugation-form" className="justify-content-center">
          <h4 className="justify-text-center">
            {verb}: {tense}
          </h4>
          {conjugations.map((conjugation) => (
            <Row className="mb-3 justify-content-center " key={conjugation.id}>
              <Col sm="2" lg="2">
                <Form.Label
                className="conjugation-form-label"
                  column="lg"
                  lg="3"
                  htmlFor={conjugation.subjectModel.id.toString()}
                  key={conjugation.subjectModel.id.toString()}
                >
                  {conjugation.subjectModel.subject}
                </Form.Label>
              </Col>
              <Col sm="2" lg="4">
                <FormControl
                  aria-label="Medium"
                  aria-describedby="inputGroup-sizing-lg"
                  required
                  key={conjugation.id.toString()}
                  id={conjugation.id.toString()}
                  onChange={handleChange}
                  type="text"
                  value={conjugation.conjugation}
                  name={conjugation.subjectModel.id.toString()}
                  className="conjugation-form-input"
                ></FormControl>
              </Col>
  
            </Row>
          ))}
  
          <Row className="justify-content-center">
            <Col md="auto">
              <Button onClick={handleSubmit} variant="primary">
                Submit
              </Button>
            </Col>
          </Row>
  
        </Form>
      </Container>
    );
}
