import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import backgroundImg from "../../assets/images/language-apple-books.jpg";
import { useLanguageContext } from './LanguageContext';

export const LanguageHome = () => {
    const { language, setLanguage } = useLanguageContext();
  return (
    <div
      className="home-page app-home"
      style={{ backgroundImage: `url(${backgroundImg})` }}
    >
      <Container className="app-home-container">
        <Row className="justify-content-center app-home">
          <Col xs={12} md={6} className="text-center ">
            <h1>Welcome to Language Master Academy</h1>
            <p>
              Test your knowledge of different languages with our fun quizzes!
            </p>

            <Link to="/quizhome">
              <Button onClick={() => setLanguage('pt') } variant="primary" size="lg">
                Learn Portuguese
              </Button>
            </Link>
            <Link to="/quizhome">
              <Button onClick={() => setLanguage('fr') }variant="primary" size="lg">
                Learn French
              </Button>
            </Link>
          </Col>
        </Row>
        
      </Container>
    </div>
  );
};
