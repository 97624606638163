import React from 'react'
import { MouseEventHandler } from 'react';
import { Button, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import baseURL from '../../config';
import TenseModel from '../models/TenseModel';
import VerbModel from '../models/VerbModel';
import './VerbTable.css';


export const VerbTable = (props: { verbs: VerbModel[],isAdmin:boolean}) => {

  const authJson = localStorage.getItem("okta-token-storage");
  const authStateToken = JSON.parse(authJson !== null ? authJson : "");
  
  async function deleteConjugation(verbid: number, tenseid: number | undefined): Promise<void> {
    try {
      const response = await fetch(`${baseURL}/api/conjugations/?verbID=${verbid}&tenseID=${tenseid}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${authStateToken?.accessToken?.accessToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        alert('Conjugations deleted successfully');
      } else {
        alert('Failed to delete quiz');
      }
    } catch (error) {
      console.error('Error occurred while deleting quiz:', error);
      // Handle error case
    }
  }

    return (
      <Table striped bordered hover responsive>
        <thead>
          <tr></tr>
          <tr>
          {props.isAdmin &&   <th>Language</th> }
            <th>Verb</th>
            <th>Tenses</th>
            {props.isAdmin && <th>Actions</th>}
          </tr>
        </thead>
        <tbody>
          {props.verbs.map((verb) => (
            <tr key={verb.verbid}>
              
              {props.isAdmin &&
              <td>{verb.language?.name}</td> }
              <td>{verb.verb}</td>
              <td>
                {verb.tenses.map((tense) => (
                  <ul key={tense.id} className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li key={`${verb.verbid}-${tense.id}`} className="nav-item">
                      <Link
                        key={verb.verbid}
                        className="nav-link nav-link-custom"
                        aria-current="page"
                        to={`/conjugations/${verb.verbid}/${tense.id}`}
                      >
                        {tense.tense}
                      </Link>
                    </li>
                  </ul>
                ))}
              </td>
              
              {props.isAdmin && 
                verb.tenses.map((tense:TenseModel) => (
                  <td>
                  <ul key={tense.id} className="navbar-nav me-auto mb-2 mb-lg-0">
                    <Col>
                  <Button className='edit-conjugation'>
                  
                    <li key={`${verb.verbid}-${tense.id}`} className="nav-item">
                      <Link
                        key={verb.verbid}
                        className="nav-link nav-link-custom"
                        aria-current="page"
                        to={`/admin/edit/conjugations/${verb.verbid}/${tense.id}`}
                      >
                       Edit {tense.tense}
                      </Link>
                    </li>
                    </Button>  
                    </Col>
                    <Col
                    className="justify-content-center"
                    >
                      
                    <Button  onClick={() => deleteConjugation(verb.verbid, tense.id)}  className='remove-conjugation'>                     
                        Delete {tense.tense}
                    </Button>
                    </Col>
                  </ul>
                  </td>
                ))}

             
              
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };


export default VerbTable
  


