import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { redirect } from 'react-router-dom';
import { Button, Container, Input } from 'reactstrap';

function FileUpload() {
    const [selectedFile, setSelectedFile] = useState('');
    const baseURL = process.env.REACT_APP_SPRING_API_URL;

    const submit = async () => {
        const formData = new FormData();
        formData.append('file', selectedFile);

        const url = `${baseURL}/api/vocabularyitem/upload`;

        const authJson = localStorage.getItem("okta-token-storage");
        const authStateToken = JSON.parse(authJson !== null ? authJson : "");

        const requestOptions = {
            method: "POST",
            headers: {
                 Authorization: `Bearer ${authStateToken?.accessToken?.accessToken}`,
                // The "Content-Type": 'multipart/form-data' can be removed as the browser will automatically include it when using FormData
            },
            body: formData // This should be at the same level as 'method' and 'headers'
        };
        const response = await fetch(url, requestOptions);

        if (!response.ok) {
            // Handle the error
            console.log("There was an error during the upload");
        } else {
            alert("File Upload Sucessfully");
            redirect("/vocabularyhome")
        }
    };

    const fileSelectedHandler = (event: any) => {
        setSelectedFile(event.target.files[0]);
    };

    return (
        <Container>
            <Row>
                <Col>
                <h4>Vocabulary Upload</h4>
                <h4>Upload a csv file in the following format:</h4>
                <p>word,translation,category,languagecode</p>
                <p>A fruta,The fruit,Comida,pt</p>
                <p>Current Language codes- fr, pt</p>
                    <Input type="file" onChange={fileSelectedHandler} />
                    <Button onClick={submit}>Upload</Button>
                </Col>
                

            </Row>
        </Container>
    );
}

export default FileUpload;
