import React, { useEffect, useState } from "react";
import Quiz from "../models/QuizModel";
import { Input, Label, Nav, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { Col, Container, Row, Spinner, Table } from "react-bootstrap";
import VerbModel from "../models/VerbModel";
import { VerbTable } from "./VerbTable";
import Pagination from "../Layouts/Pagination";
import { useLanguageContext } from "../Language/LanguageContext";

export const ConjugationHome = () => {
  const [verbs, setVerbs] = useState<VerbModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [httpError, setHttpError] = useState(null);

  // To hold the actual data
  const [loading, setLoading] = useState(true);
  const [recordsPerPage] = useState(4);

  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = verbs.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(verbs.length / recordsPerPage)

  const baseURL=process.env.REACT_APP_SPRING_API_URL;
  const { language, setLanguage } = useLanguageContext();
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const fetchVerbs = async () => {
      let url: string =  `${baseURL}/api/conjugations/verbs`;

      let useSearchText=false;
      if (searchText !== '') {
        url = url.concat(`/search?text=${searchText}&`);
        useSearchText=true;
       }
 
       if(!useSearchText){
         url = url.concat('?')
       }
       url = url.concat(`language=${language}`);

      const response = await fetch(url);

      if (response != null && !response.ok) {
        throw new Error("Something went wrong!");
      }

      const responseJson = await response.json();

      const responseData = responseJson;

      const verbTenseDTOs = [];

      for (const key in responseData) {
        verbTenseDTOs.push({
          verbid: responseData[key].id,
          verb: responseData[key].verb,
          tenses: responseData[key].tenses,
          language: responseData[key].language,
        });
      }

      setVerbs(verbTenseDTOs);
      setIsLoading(false);
    };
    fetchVerbs().catch((error: any) => {
      setIsLoading(false);
      setHttpError(error.message);
    });
  }, [language,searchText]);

  const handleSearchTextChange = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    setSearchText(newValue);
  }

  if (isLoading) {
    return (
      <Container className="text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
      <Container className="container text-center">
        <Row>
          <Col>
            <h4 className=" text-center">Verb Conjugation Tests</h4>
          </Col>
        </Row>
        <Row>
        <Col>

       

        <Input placeholder="Search" onChange={handleSearchTextChange} type="text" name={searchText} />
        </Col>
        <Col>
        </Col>
        
       </Row>
  
        <VerbTable verbs={currentRecords} isAdmin={false} />
        <Pagination
        nPages={nPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      </Container>
  );
};


