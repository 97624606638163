class VerbLanguageDto {
    verb: string;
    language: string;
  
    constructor(
        verb: string,
        language: string,
    ) {
    this.verb=verb;
    this.language=language;
  }
}
  
  export default VerbLanguageDto;
  