import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import { oktaConfig } from "./lib/oktaConfig";
import { LoginCallback, Security } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import LoginWidget from "./Auth/LoginWidget";
import { QuizHome } from "./components/Quiz/QuizHome";
import { NavBar } from "./components/Layouts/NavBar";
import "./App.css";
import { ConjugationHome } from "./components/Conjugation/ConjugationHome";
import { ConjugationPage } from "./components/Conjugation/ConjugationPage";
import { QuizPage } from "./components/Quiz/QuizPage";
import { PortugueseAppHome } from "./components/Layouts/PortugueseAppHome";
import { AdminHome } from "./components/Admin/AdminHome";
import { AdminCreateNewQuiz } from "./components/Admin/Quizzes/AdminCreateNewQuiz";
import { AdminQuizzesHome } from "./components/Admin/Quizzes/AdminQuizzesHome";
import { AdminConjugationHome } from "./components/Admin/Conjugations/AdminConjugationsHome";
import { AdminCreateNewConjugation } from "./components/Admin/Conjugations/AdminCreateNewConjugation";
import { LanguageProvider } from "./components/Language/LanguageContext";
import { LanguageHome } from "./components/Language/LanguageHome";
import { AdminConjugationEdit } from "./components/Admin/Conjugations/AdminConjugationEdit";
import VocabularyPage from "./components/Vocabulary/VocabularyPage";
import VocabularyHomePage from "./components/Vocabulary/VocabularyHomePage";
import FileUpload from "./components/Admin/VocabularyItems/VocabularyFileUpload";
import ReactGA from 'react-ga';
import QuizFileUpload from "./components/Admin/Quizzes/QuizFileUpload";


export const App = () => {
  const oktaAuth = new OktaAuth(oktaConfig);

  const customAuthHandler = () => {
    navigate("/login");
  };

  const navigate = useNavigate();

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };


  const TRACKING_ID = "G-9MMFMFW850"; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);

  return (
    <LanguageProvider>
    <Security
      oktaAuth={oktaAuth}
      restoreOriginalUri={restoreOriginalUri}
      onAuthRequired={customAuthHandler}
    >
      <NavBar />
      <Routes>
        <Route
          path="/conjugations/:verbid/:tenseid"
          element={<ConjugationPage />}
        />
        <Route path="/" element={<LanguageHome />} />
        <Route path="/home" element={<LanguageHome />} />
        <Route path="/quizhome" element={<QuizHome />} />
        <Route path="/portuguese" element={<PortugueseAppHome/>}/>
        <Route path="/quizzes/:id" element={<QuizPage />} />
        <Route path="/conjugations" element={<ConjugationHome />} />
        <Route
          path="/login"
          element={<LoginWidget config={oktaConfig}></LoginWidget>}
        />
        <Route path="/login/callback" element={<LoginCallback />} />
        <Route path="/admin" element={<AdminHome />} />
        <Route path="/adminquizhome" element={<AdminQuizzesHome />} />
        <Route path="/admincreatequiz" element={<AdminCreateNewQuiz />} />
        <Route path="/admincreateconjugation" element={<AdminCreateNewConjugation />} />
        <Route path="/admin/edit/conjugations/:verbid/:tenseid" element={<AdminConjugationEdit />} />
        <Route path="/adminconjugationshome" element={<AdminConjugationHome />} />

        <Route path="/vocabularyhome" element={<VocabularyHomePage/>}/>
        <Route path="/vocabulary/:category" element={<VocabularyPage/>}/>
        
        <Route path="/vocabfileupload" element={<FileUpload/>}/>

        <Route path="/quizfileupload" element={<QuizFileUpload/>}/>
        
        
        
  
      </Routes>
    </Security>
     </LanguageProvider>
  );
};
