import { useOktaAuth } from "@okta/okta-react";
import React, { useEffect, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { Button } from "reactstrap";
import { useLanguageContext } from "../Language/LanguageContext";
import franceFlag from "../../assets/images/france_flag.png";
import brazilFlag from "../../assets/images/brazil_flag.png";
import "./NavBar.css";
import setAnalyticsEventTracker from "../../lib/useAnalyticsEventTracker";


export const NavBar = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [userGroups, setUserGroups] = useState<string[]>([]);
  const authJson = localStorage.getItem("okta-token-storage");
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const { language, setLanguage } = useLanguageContext();
  const [ flagImage, setFLagImage] = useState("");

  useEffect(() => {
    if (language === 'fr') {
      setFLagImage(franceFlag);
    } else {
      setFLagImage(brazilFlag);
    } 
    // Get the user's groups
    if (authState?.isAuthenticated && !authState.isPending) {
      setIsAuthenticated(true);

      const groups = new Array<string>();
      const token = oktaAuth.getIdToken;
      const authStateToken = JSON.parse(authJson !== null ? authJson : "");
      authStateToken.idToken.claims?.Groups.forEach((group: string) =>
        groups.push(group)
      );
      setUserGroups(groups);
      if (groups.includes("Admin")) {
        setIsAdmin(true);
      }
    }
  }, [authState]);

  if (!authState) {
    return <div>Loading</div>;
  }


  const handleLogout = async () => oktaAuth.signOut();
  return (
    <nav className="navbar navbar-expand-lg main-color ">
      <div className="container-fluid main-color">
        <a className="navbar-brand nav-link-custom" href="/">
          Home
        </a>
        <button
          className="navbar-toggler nav-toggle"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={()=>setAnalyticsEventTracker('home'+language)}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
           
          
            <li className="nav-item">
           
              <NavLink
                className="nav-link nav-link-custom"
                aria-current="page"
                to="/quizhome"
              >
                 <button  className="nav-link nav-link-custom" onClick={()=>setAnalyticsEventTracker('Quizzes'+language)}>
                Quizzes{" "}
                </button>
              </NavLink>
             
            </li>
            

            <li className="nav-item">
              <NavLink
                className="nav-link nav-link-custom"
                aria-current="page"
                to="/conjugations"
              >
                <button className="nav-link nav-link-custom"  onClick={()=>setAnalyticsEventTracker('Conjugation Tests'+language)}>
                Conjugation Tests{" "}
                </button>
              </NavLink>
            </li>
          
            <li className="nav-item">
              <NavLink
                className="nav-link nav-link-custom"
                aria-current="page"
                to="/vocabularyhome"
              >
                  <button className="nav-link nav-link-custom"   onClick={()=>setAnalyticsEventTracker('Vocabulary'+language)}>
                Vocabulary{" "}
                </button>
              </NavLink>
            </li>

            {isAuthenticated && isAdmin && (
              <li className="nav-item">
                <NavLink
                  className="nav-link nav-link-custom"
                  aria-current="page"
                  to="/admin"
                >
                   <button className="nav-link nav-link-custom"   onClick={()=>setAnalyticsEventTracker('Admin'+language)}>
                  Admin{" "}
                  </button>
                </NavLink>
              </li>
            )}
          </ul>
          
          <img className="flag-icon" src={flagImage} alt="Flag Language image" />
          <div className="dropdown" data-bs-theme="light">
  <button className="btn btn-secondary dropdown-toggle dropdown-languages" type="button" id="dropdownMenuButtonLight" data-bs-toggle="dropdown" aria-expanded="false">
    Languages
  </button>
  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButtonLight">
    <li ><Button onClick={() => setLanguage('pt')}  className="dropdown-item"> <Link  to="/quizhome"> <img className="flag-icon-dropdown" src={brazilFlag} alt="Flag Language image" /> Portuguese</Link></Button> </li>
    <li><Button onClick={() => setLanguage('fr')}   className="dropdown-item"> <Link to="/quizhome"> <img className="flag-icon-dropdown" src={franceFlag} ></img> French </Link></Button></li>
  </ul>
</div>
    
          {!isAuthenticated && (
            <Button className="sign-in-btn">
              <NavLink className="nav-link" to="/login">
                Sign In
              </NavLink>
            </Button>
          )}
          {isAuthenticated && (
            <Button className="sign-in-btn" onClick={handleLogout}>
              <NavLink className="nav-link" to="/login">
                Sign Out
              </NavLink>
            </Button>
          )}
        </div>
      </div>
    </nav>
  );
};
