import React, {useEffect, useState } from "react";
import { Container, Table,Spinner } from "react-bootstrap";
import Quiz from "../models/QuizModel";
import { Link } from "react-router-dom";
import Pagination from "../Layouts/Pagination";
import "./QuizHome.css"; 
import { useLanguageContext } from "../Language/LanguageContext";
import { Col, Input, Row } from "reactstrap";


export const QuizHome = () => {
  const [quizzes, setQuizzes] = useState<Quiz[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [httpError, setHttpError] = useState(null);
  const [searchText, setSearchText] = useState('');


  // To hold the actual data
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [recordsPerPage] = useState(5);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = quizzes.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(quizzes.length / recordsPerPage)
  const baseURL=process.env.REACT_APP_SPRING_API_URL;
  const { language, setLanguage } = useLanguageContext();

  useEffect(() => {
    const fetchQuizzes = async () => {
      let url: string = `${baseURL}/api/quizzes/all`;

      let useSearchText=false;

      if (searchText !== '') {
       url = url.concat(`/search?text=${searchText}&`);
       useSearchText=true;
      }

      if(!useSearchText){
        url = url.concat('?')
      }
      url = url.concat(`language=${language}`);


      const response = await fetch(url);

      if (response != null && !response.ok) {
        throw new Error("Something went wrong!");
      }

      const responseJson = await response.json();

      const responseData = responseJson;

      const loadedQuizzes: Quiz[] = [];

      for (const key in responseData) {
        loadedQuizzes.push({
          id: responseData[key].id,
          lastUpdated: responseData[key].lastUpdated,
          version: responseData[key].version,
          name: responseData[key].name,
          quizType: responseData[key].quizType,
        });
      }

      setQuizzes(loadedQuizzes);
      setIsLoading(false);
    };
    fetchQuizzes().catch((error: any) => {
      setIsLoading(false);
      setHttpError(error.message);
    });
  }, [language,searchText]);

  const handleSearchTextChange = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    setSearchText(newValue);
  }

  if (isLoading) {
    return (
      <Container className="text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  

  return (
      <Container className="text-center">
      <h4>Quizzes</h4>
      <Row>
       
        <Col>
        <Input placeholder="Search" onChange={handleSearchTextChange} type="text" name={searchText} />
        </Col>
        <Col>
        </Col>
      </Row>
   
        <Table striped bordered hover>
        
       
          <thead>
  
          </thead>
          <tbody>
            {currentRecords.map((quiz) => (
              <tr key={quiz.id}>
                <td>
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li key={quiz.id} className="nav-item">
                      <Link
                        key={quiz.id}
                        className="nav-link nav-link-custom"
                        aria-current="page"
                        to={`/quizzes/${quiz.id}`}
                      >
                        {quiz.name}{" "}
                      </Link>
                    </li>
                  </ul>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
        nPages={nPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      </Container>
  );
};
