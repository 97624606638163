import React, { useEffect, useState } from 'react';
import { Container, Table } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { Col, Input, Row } from 'reactstrap';
import { useLanguageContext } from '../Language/LanguageContext';
import Pagination from '../Layouts/Pagination';

interface LanguageCategoryDto {
  language: string;
  categories:[string];
}


const VocabularyHomePage: React.FC = ({ }) => {

  const { language, setLanguage } = useLanguageContext();

  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [httpError, setHttpError] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [recordsPerPage] = useState(10);

  const baseURL = process.env.REACT_APP_SPRING_API_URL;
  const [languageCategoryDto, setLanguageCategoryDto] = useState<LanguageCategoryDto>();
  const [categories, setCategories] = useState<[string]>();

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const [searchText, setSearchText] = useState('');

  const currentRecords = categories?.slice(indexOfFirstRecord, indexOfLastRecord);
  let nPages=0;
  if(categories!==undefined){
    nPages= Math.ceil(categories.length / recordsPerPage)

  }
  
  
  
  useEffect(() => {
    const fetchVocabulary = async () => {
      setIsLoading(true);

      let url=`${baseURL}/api/vocabulary/categories`;
      let useSearchText=false;

      if (searchText !== '') {
       url = url.concat(`/search?searchText=${searchText}&`);
       useSearchText=true;
      }

      if(!useSearchText){
        url = url.concat('?')
      }
      url = url.concat(`language=${language}`);

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Something went wrong!");
        }
        const responseData = await response.json();
        let loadedLanguageDto: LanguageCategoryDto=responseData;


       setLanguageCategoryDto(loadedLanguageDto);
       if(loadedLanguageDto.categories!=null){
        setCategories(loadedLanguageDto.categories);
       }
     
        setIsLoading(false);
      } catch (error: any) {
        setIsLoading(false);
        setHttpError(error.message);
      }
    };

    fetchVocabulary();
  }, [language,searchText]);

  const handleSearchTextChange = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    setSearchText(newValue);
  }

  return (
    <Container className="container text-center">
      <h2>Vocabulary Builder</h2>
      <Row>  
       <Col>
       <Input placeholder="Search" onChange={handleSearchTextChange} type="text" name={searchText} />
       </Col>
       <Col>
       </Col>
     </Row>
      <Table striped bordered hover responsive>
        <thead>
          <th>Categories</th>
        </thead>
        <tbody>
          {currentRecords?.map((category) => (
             <tr id={category}>
                <td className="text-center">     
              <Link to={`/vocabulary/${category}`}>{category}</Link>
              </td>
              </tr>
          ))}
          </tbody>
      </Table>
      <Pagination
        nPages={nPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </Container>
  );
};

export default VocabularyHomePage;
