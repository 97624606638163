import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { redirect } from 'react-router-dom';
import { Button, Container, Input } from 'reactstrap';

function QuizFileUpload() {
    const [selectedFile, setSelectedFile] = useState('');
    const baseURL = process.env.REACT_APP_SPRING_API_URL;

    const submit = async () => {
        const formData = new FormData();
        formData.append('file', selectedFile);

        const url = `${baseURL}/api/quiz/upload`;

        const authJson = localStorage.getItem("okta-token-storage");
        const authStateToken = JSON.parse(authJson !== null ? authJson : "");

        const requestOptions = {
            method: "POST",
            headers: {
                 Authorization: `Bearer ${authStateToken?.accessToken?.accessToken}`
            },
            body: formData // This should be at the same level as 'method' and 'headers'
        };
        console.log(url);
        console.log(authStateToken);
        console.log(requestOptions);
       
        const response = await fetch(url, requestOptions);
        console.log(response);
        if (!response.ok) {
            // Handle the error
           
            console.log("There was an error during the upload");
        } else {
            alert("File Upload Sucessfully");
            redirect("/quizhome")
        }
    };

    const fileSelectedHandler = (event: any) => {
        setSelectedFile(event.target.files[0]);
    };

    return (
        <Container>
            <Row>
                <Col>
                <h4>Quiz Upload</h4>
                <h4>Upload a csv file in the following format:</h4>
              <p>  O Corpo,pt</p>
              <p>  q, What is the Portuguese word for 'nose'?,,</p>
              <p>  a, Boca, y,</p>
                <p>Current Language codes- fr, pt</p>
                    <Input type="file" onChange={fileSelectedHandler} />
                    <Button onClick={submit}>Upload</Button>
                </Col>
                

            </Row>
        </Container>
    );
}

export default QuizFileUpload;
