import React from 'react'
import { Col, Row, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import baseURL from '../../../config'
import Quiz from '../../models/QuizModel'

export const AdminQuizzesTable = (props: { currentRecords: Quiz[] } ) => {
  const baseURL = process.env.REACT_APP_SPRING_API_URL;

  async function deleteQuiz(id:number) {
    try {
      const response = await fetch(`${baseURL}/api/quizzes/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      if (response.ok) {
        console.log('Quiz deleted successfully');
        // Perform any additional actions after successful deletion
        props.currentRecords.filter((quiz:Quiz) => quiz.id !== id);
      } else {
        console.error('Failed to delete quiz');
        // Handle error case
      }
    } catch (error) {
      console.error('Error occurred while deleting quiz:', error);
      // Handle error case
    }
  }

  return (
    <div className="mt-5 container text-center">
    <ul className="navbar-nav ms-auto"></ul>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Quizzes</th>
        </tr>
      </thead>
      <tbody>
        {props.currentRecords.map((quiz:Quiz) => (
          <tr key={quiz.id}>
            <td>
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li key={quiz.id} className="nav-item">
                  <Row>
                    <Col>
                      <span>{quiz.name} </span>
                    </Col>

                    <Col>
                      <Link
                        key={quiz.id}
                        aria-current="page"
                        to={`/quizzes/${quiz.id}`}
                      >
                        {" "}
                        View{" "}
                      </Link>
                    </Col>

                    <Col>
                      <Link
                        key={quiz.id}
                        aria-current="page"
                        to={`/quizzes/edit/${quiz.id}`}
                      >
                        Edit
                      </Link>
                    </Col>

                    <Col>
                    
                      <Button variant={'Danger'} onClick={() => deleteQuiz(quiz.id)}>
                        Delete
                      </Button>
                    </Col>
                  </Row>
                </li>
              </ul>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
    </div>
  )
}
