import LanguageModel from "./LanguageModel";
import TenseModel from "./TenseModel";

class VerbModel {
  verbid: number;
  verb: string;
  tenses: TenseModel[];
  language: LanguageModel | undefined;

  constructor(verbid: number, verb: string,  tenses: TenseModel[]) {
    this.verb = verb;
    this.verbid = verbid;
    this.tenses = tenses;
  }
}

export default VerbModel;
