import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useLanguageContext } from '../Language/LanguageContext';
import QuizModel from '../models/QuizModel';

interface VocabularyItem {
  word: string;
  audioUrl: string;
  category: string;
  translation: string;
}


const VocabularyPage: React.FC = ({ }) => {


  const playAudio = async (audioUrl: string) => {
    //TODO add code to get langauge
    if(params.category!==undefined){
    const audio = new Audio(audioUrl);
    audio.play();
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [httpError, setHttpError] = useState(null);
  const [vocabItems, setVocabItems] = useState<VocabularyItem[]>([]);
  const { language, setLanguage } = useLanguageContext();

  const params = useParams();

  const baseURL = process.env.REACT_APP_SPRING_API_URL;

  let languageCodeMap = new Map<string, string>();
  languageCodeMap.set("pt","português");
  languageCodeMap.set("fr","french");

  useEffect(() => {
    const fetchVocabulary = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${baseURL}/api/vocabulary?category=${params.category}&language=${language}`
        );
        if (!response.ok) {
          throw new Error("Something went wrong!");
        }
        const responseData = await response.json();
        const loadedVocabularyItems: VocabularyItem[] = [];

        for (const key in responseData) {
          loadedVocabularyItems.push({
            word: responseData[key].word,
            audioUrl: responseData[key].audioUrl,
            category: responseData[key].category,
            translation: responseData[key].translation,
          });
        }

        setVocabItems(loadedVocabularyItems);
        setIsLoading(false);
      } catch (error: any) {
        setIsLoading(false);
        setHttpError(error.message);
      }
    };

    fetchVocabulary();
  }, []);


  return (

    <Container className="container text-center">
      <h1>{params.category}</h1>
    <Table striped bordered hover responsive>
      
      <thead>
<th>
  {
    languageCodeMap !== undefined &&
    languageCodeMap.get(language) !== undefined &&
    languageCodeMap.has(language) &&
    languageCodeMap.get(language) !== undefined
      ? languageCodeMap.get(language)!.charAt(0).toUpperCase() + languageCodeMap.get(language)!.slice(1)
      : undefined
  }
</th>
        <th>English</th>
        <th>Listen</th>
      </thead>
      <tbody>
        {vocabItems.map((item, index) => (
          <tr key={index}><td>{item.word}</td><td>{item.translation}</td><td> <button onClick={() => playAudio(`${item.audioUrl}`)}>Play</button>
          </td>
        </tr>
      ))}
      </tbody>
    </Table>
    </Container>
  );
        }

  export default VocabularyPage;
